<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="tariffEdit"
            :inputComponents="inputComponents"
          >
            <template v-slot:margin>
              <div v-for="(n, i) in 15" v-bind:key="i + Math.random()">
                <br />
              </div>
            </template>
          </BaseTabGroup>
          <br />
        </div>
      </template>

      <template v-slot:footer="{ cancel }">
        <!-- 設定ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.setting"
          @click="setting(obj)"
        />
        <!--  CSV出力ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.file_print"
          @click="openSubModal(obj, 'download-modal')"
        />
        <!--  CSV取込ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.file_read"
          @click="openSubModal(obj, 'upload-modal')"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>

    <!-- CSV出力モーダル -->
    <ConfirmModal
      id="download-modal"
      :columnInfos="messageInfo"
      :message="downloadMessage"
      @confirm="download(obj)"
    />

    <!-- CSV取込モーダル -->
    <UploadModal
      id="upload-modal"
      :columnInfos="fileInfo"
      @upload="setUploadFile"
    />
  </div>
</template>

<script>
import TariffEditInfo from './TariffEditInfo.js'
import { getListOptions, openSubModal } from '@/common/Common.js'
import {
  SCREEN_ID,
  DETAIL_SCREEN_KBN,
  VISIBILITY,
  SLOT_NAME,
} from '@/common/const.js'
import {
  init,
  setting,
  upload,
  download,
  setCSVbtnDisable,
} from '@/master/tariff/TariffEditModal.js'

const screenId = SCREEN_ID.TARIFF_EDIT_MODAL

export default {
  props: {
    getDatas: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    getDatas() {
      init(this)
    },
  },

  data() {
    return {
      tariffEdit: new TariffEditInfo(),
      modalKbn: 0,
      obj: this,
      tariffValidPeriodId: null,
    }
  },

  computed: {
    inputComponents() {
      return [
        {
          // タブ
          label: this.detailInfo.tab1.label,
          slot: SLOT_NAME.MARGIN,
          inputComponents: [
            {
              // 有効開始日付
              type: 'date',
              id: 'validBeginDate',
              columnInfo: this.detailInfo.valid_begin_date,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
          ],
        },
      ]
    },

    detailInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)('detail_info')
      if (this.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.DETAIL) {
        info.valid_begin_date.visibility = VISIBILITY.DISABLE
      } else {
        info.valid_begin_date.visibility = VISIBILITY.VISIBLE
      }
      return info
    },
    resultControlInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
      // 設定、CSV出力、CSV取込
      this.setCSVbtnDisable(this.obj, info)
      return info
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.resultControlInfo.file_print.label
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
  },

  methods: {
    getListOptions,
    init,
    openSubModal,
    setting,
    upload,
    download,
    setCSVbtnDisable,

    setUploadFile(file) {
      upload(this, file)
    },
  },
}
</script>

<style scoped></style>
