import {
  DETAIL_SCREEN_KBN,
  VISIBILITY,
  SCREEN_ID,
  MSG_TYPE,
  ROW_KBN,
} from '@/common/const.js'
import {
  openMsgModal,
  downloadCSV,
  csvFileName,
  formatYYYYMMDD,
  displaYyyymmdd,
} from '@/common/Common.js'
import TariffEditInfo from './TariffEditInfo'
import TariffService from './TariffService'
import _ from 'lodash'

const screenId = SCREEN_ID.TARIFF_EDIT_MODAL

export function init(obj) {
  if (obj.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.NEW) {
    //追加
    obj.tariffEdit = new TariffEditInfo()
  } else if (obj.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.COPY) {
    //「コピー」
    obj.tariffEdit = new TariffEditInfo()
    if (obj.getDatas.prevValidBeginDate != null) {
      // YYYY/MM/DD + days
      let addedStartDate = addDaysToString(
        obj,
        1,
        obj.getDatas.prevValidBeginDate
      )
      obj.tariffEdit.validBeginDate = addedStartDate
    }
  } else {
    //「編集」
    obj.tariffEdit.validBeginDate =
      obj.getDatas.selectedItem[0].valid_begin_date
    if (obj.getDatas.selectedItem[0]) {
      obj.tariffValidPeriodId =
        obj.getDatas.selectedItem[0].tariff_valid_period_id
    }
  }
  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export function setCSVbtnDisable(obj, info) {
  // CSV出力、CSV取込ボタン 表示・非表示
  if (
    obj.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.DETAIL &&
    obj.getDatas.selectedItem[0].tariff_valid_period_id != null
  ) {
    // #2768対応
    // info.file_print.visibility = VISIBILITY.VISIBLE
    // info.file_read.visibility = VISIBILITY.VISIBLE
  } else {
    info.file_print.visibility = VISIBILITY.INVISIBLE
    info.file_read.visibility = VISIBILITY.INVISIBLE
  }
  // 設定ボタン 表示・非表示
  if (
    obj.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.NEW ||
    obj.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.COPY
  ) {
    // #2768対応
    // info.setting.visibility = VISIBILITY.VISIBLE
  } else {
    info.setting.visibility = VISIBILITY.INVISIBLE
  }
  return info
}

// 「CSV取込」ボタンクリック
export function upload(obj, file) {
  if (file == null || file.name.includes('.csv') == false) {
    // csvファイルを選択してください
    openMsgModal(
      obj,
      obj.MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG936E')
    )
    return
  }

  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'csv')
  formData.append('tariffValidDateId', obj.tariffValidPeriodId)
  obj.$store.dispatch('init/setServiceLoading')
  TariffService.upload(formData)
    .then(
      () => {
        obj.$bvModal.hide('upload-modal')
        openMsgModal(
          obj,
          obj.MSG_TYPE.I,
          obj.$store.getters['init/getMessage']('YZ00MG958I')
        )
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 「CSV出力」ボタンクリック
export function download(obj) {
  obj.$bvModal.hide('download-modal')

  //let filename = 'H013-40.csv'
  let filename = csvFileName(obj, screenId)

  obj.$store.dispatch('init/setServiceLoading')
  TariffService.download(filename, obj.tariffValidPeriodId)
    .then(
      (response) => {
        if (response.data == '') {
          // 警告モーダル表示
          openMsgModal(
            obj,
            obj.MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          downloadCSV(filename, response.data)
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export async function setting(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  // 編集の場合、日付変更できないため、チェックしない
  if (obj.getDatas.detailScreenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    let dateA = obj.tariffEdit.validBeginDate
    // YYYY-MM-DD -> YYYYMMDD -> YYYY/MM/DD
    dateA = formatYYYYMMDD(obj, dateA)
    dateA = displaYyyymmdd(obj, dateA)
    let dateB = obj.getDatas.prevValidBeginDate

    // // 入力した有効開始日付(A)が当日(B)より
    // //  未来日になっているかチェック
    // if (isAfterToday(obj, dateA) == false) {
    //   // 期間日付の入力に不整合があります。
    //   openMsgModal(
    //     obj,
    //     MSG_TYPE.W,
    //     obj.$store.getters['init/getMessage']('YZ00MG992E')
    //   )
    //   return
    // }

    // 入力した有効開始日付(A)が最新有効開始日付(B)より
    //  未来日になっているかチェック
    if (
      obj.getDatas.rowKbn != ROW_KBN.NONE &&
      isAfterAthanB(obj, dateA, dateB) == false
    ) {
      // 期間日付の入力に不整合があります。
      openMsgModal(
        obj,
        MSG_TYPE.W,
        obj.$store.getters['init/getMessage']('YZ00MG992E')
      )
      return
    }
  }

  obj.$bvModal.hide('tariff-edit-modal')
  let newEdit = _.cloneDeep(obj.tariffEdit)
  newEdit.validBeginDate = formatYYYYMMDD(obj, newEdit.validBeginDate)
  obj.$emit('after-close-set', newEdit)
}

/**
 * dateAがdateBより未来日か
 * @param {*} obj
 * @param {*} dateA
 * @param {*} dateB
 */
function isAfterAthanB(obj, dateA, dateB) {
  let date1 = obj.$moment(new Date(dateA))
  let date2 = obj.$moment(new Date(dateB))
  let isAfter = date1.isAfter(date2)
  return isAfter
}

// /**
//  * 当日より未来日か
//  * @param {*} obj
//  * @param {*} dateA
//  */
// function isAfterToday(obj, dateA) {
//   let today = obj.$moment(new Date()).format('YYYYMMDD')
//   today = displaYyyymmdd(obj, today)
//   return isAfterAthanB(obj, dateA, today)
// }

/**
 * YYYY/MM/DD + days
 * @param {*} obj
 * @param {*} days
 * @param {*} target
 */
function addDaysToString(obj, days, target) {
  let tStartDate = obj.$moment(new Date(target))
  let addedStartDate = tStartDate.add(days, 'days').format('YYYYMMDD')
  addedStartDate = displaYyyymmdd(obj, addedStartDate)
  return addedStartDate
}
