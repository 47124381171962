/**
 * 場所マスタデータ.
 * @class
 * @exports
 */
export default class tariffEdit {
  //--------------TAB1------------//
  validBeginDate = null
  tariffId
}
